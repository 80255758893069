import React from 'react'
import SEO from "../components/seo"
import { Row, Container } from 'reactstrap'
import CardComponent from '../components/cardComponent'
import Button from "../components/btn"
import Layout from '../components/layout'
import '../assets/stylesheets/components/round-featured.scss'
import '../assets/stylesheets/components/card-deck.scss'
import '../assets/stylesheets/components/two-column-boxes.scss'
import businessLogo from '../assets/images/business_cube_logo.png';
import ntsLogo from '../assets/images/logo_nts_informatica.png';
import cubeScreenshot from '../assets/images/cube.png';
import easyToUse from '../assets/images/easy.svg';
import dataMigration from '../assets/images/migration.svg';
import customerCare from '../assets/images/customercare.svg';
import windowsLogo from '../assets/images/win_logo.png';
import androidLogo from '../assets/images/android_logo.png';
import appleLogo from '../assets/images/apple_logo.png';

let BusinessCube = () => {


  return (
    <Layout>
    <SEO title='NTS Business Cube'></SEO>
      <Container>
        <Row className="py-5 flex-column">
          <div className="container d-flex justify-content-center mt-3 mb-3">
            <img className="mb-3" src={businessLogo} width="200px" alt="NTS Business Cube Logo" />
            <img className="mb-3 ml-5" src={ntsLogo} width="100px" alt="NTS Informatica Logo" />  
          </div>
          <div className="container d-flex flex-column">
            <h2 className="text-center"><b>Business Cube:</b> il miglior software ERP per la tua azienda.</h2>
            <p className="text-center">Business Cube, il software gestionale ERP di NTS Informatica, offre una gamma molto ampia di funzioni applicative, per gestire in modo integrato i processi delle aziende che operano nei settori della produzione, del commercio e dei servizi.</p>
            <div className="d-flex justify-content-center mt-3">
              <Button to="#cube-modules" className="btn btn-primary btn-lg ml-auto mr-auto">Scopri tutti i moduli</Button>
            </div>
            <img className="mt-5 mb-5" src={cubeScreenshot} alt="NTS Informatica Business Cube" />
            <div className="d-flex flex-column justify-content-center mt-5 mb-5 bg-light p-5">
              <div className="d-flex justify-content-around flex-wrap mx-auto col-lg-6">
                <img className="" src={androidLogo} width="100px" alt=""></img>
                <img className="" src={windowsLogo} width="100px" alt=""></img>
                <img className="" src={appleLogo} width="100px" alt=""></img>
              </div>
              <h3 className="text-center mt-5 text-bold">Business Cube è compatibile con tutti i sistemi operativi: Windows, iOS e Android. </h3>
              <h5 className="col-lg-8 text-center mt-3 mx-auto">Business Cube è progettato per gli ambienti più diffusi e può essere fruito anche via Web e con semplici App scaricabili su Smartphone e Tablet.</h5>
            </div>
            {/* BOX 1 */}
            <div className="two-column-boxes">
              <div className="box">
                <h3 className="mt-5 text-bold">Semplice da usare</h3>
                <p>Business Cube propone caratteristiche funzionali pensate per l’utilizzatore finale, supportando l’operatore nelle sue attività quotidiane attraverso l’utilizzo di programmi studiati con precisione funzionale ed estetica.</p>
              </div>
              <div className="box">
                <img src={easyToUse} width="380px" alt=""></img>
              </div>
            </div>
            {/* BOX 2 */}
            <div className="two-column-boxes inverted">
              <div className="box">
                <h3 className="mt-5 text-bold">Migrazione semplice dei dati da altri software</h3>
                <p>NTS ha sviluppato una suite per la conversione dei dati provenienti da software gestionali concorrenti che garantiscono la piena compatibilità e integrità referenziale con Business Cube.</p>
              </div>
              <div className="box">
                <img className="border-radius-15" src={dataMigration} width="380px" alt=""></img>
              </div>
            </div>
            {/* BOX 3 */}
            <div className="two-column-boxes">
              <div className="box">
                <h3 className="mt-5 text-bold">Ti aiutiamo noi!</h3>
                <p>Tecno Computers assicura ai propri Clienti un attento servizio di supporto pre e post-vendita. Personale esperto e qualificato, disponibilità e celerità sono le caratteristiche del nostro servizio di assistenza, completamente orientato alla soddisfazione del Cliente.</p>
              </div>
              <div className="box">
                <img className="border-radius-15" src={customerCare} width="380px" alt=""></img>
              </div>
            </div>
          </div>
          <div id="cube-modules" className="container mt-5">
            <h1 className="text-center mb-5">Elenco dei moduli di Business Cube</h1>
            <div>
              <h2 className="cards__title">Amministrazione</h2>
              <div className="cards__container">
                <CardComponent frontText="Cespiti" backText="Gestione di anagrafiche cespite, con tabelle ministeriali precompilate, calcolo automatico delle quote di ammortamento (civilistico e fiscale) annuali e infra-annuali, completamente integrato con il modulo di contabilità. Calcolo delle minus/plusvalenze e gestione del super ammortamento." />
                <CardComponent frontText="Ritenute d'acconto" backText="Gestione compensi da Professionisti e Agenti, avvisi di parcella e scadenzario delle Ritenute. Gestione della Certicazione Unica Telematico con possibilità di generare le in formato PDF da inviare ai singoli percipienti e creazione file per l’invio all’Agenzia delle Entrate." />
                <CardComponent frontText="Dichiarazioni d'intento" backText="Funzionalità utilizzata sia per esportatori abituali che non, per avere sotto controllo tutte le attività collegate alla normativa, con generazione delle lettere di dichiarazione di intento da inviare ai fornitori, e ricezione delle ricevute da parte dell’Agenzia delle Entrate." />
                <CardComponent frontText="Adempimenti fiscali" backText="Moduli o funzionalità necessarie per gli adempimenti normativi tipici di un’azienda, come la gestione dei Registri IVA e Liquidazioni mensili/trimestrali, la Comunicazione Liquidazione IVA Periodica e la Gestione della Comunicazione Fatture Emesse e Ricevute (Comunicazione dei dati fattura transfrontaliere), Gestione F24 con integrazione a Liquidazioni IVA e Ritenute d’Acconto" />
                <CardComponent frontText="Contabilità ordinaria, semplificata e IVA" backText="Moduli o funzionalità necessarie per gli adempimenti normativi tipici di un’azienda, come la gestione dei Registri IVA e Liquidazioni mensili/trimestrali, la Comunicazione Liquidazione IVA Periodica e la Gestione della Comunicazione Fatture Emesse e Ricevute (Comunicazione dei dati fattura transfrontaliere), Gestione F24 con integrazione a Liquidazioni IVA e Ritenute d’Acconto" />
                <CardComponent frontText="Intrastat" backText="Estrazione automatica dei movimenti di magazzino per la generazione del le in tracciato Intr@web e Telematico, con possibilità di integrare manualmente eventuali operazioni integrative." />
              </div>
            </div>

            <div className="mt-5">
              <h2 className="cards__title">Fattura elettronica</h2>
              <div className="cards__container">
                <CardComponent colorStyle="color-2" frontText="MODULO FATTURA ELETTRONICA" backText="Perfettamente integrato al ciclo attivo e passivo del software gestionale Business, il modulo consente di gestire tutti gli adempimenti legati alla produzione delfile XML, alla gestione delle notifiche ricevute dal Sistema di Interscambio in merito allo stato della fattura, al ricevimento delle fatture passive. Il modulo incorpora anche le funzioni di integrazione bi-direzionale con l’Hub Digitale per la Fattura Elettronica." />
                <CardComponent colorStyle="color-2" frontText="MODULO FATTURA ELETTRONICA PLUS" backText="Il modulo permette di generare, partendo dal file XML definito dall’Agenzie delle Entrate, tracciati arricchiti con maggiori informazioni. Con il file XML arricchito l’azienda può quindi attivare automatismi di interscambio con i software gestionali dei propri clienti e fornitori. Consente inoltre la registrazione pilotata della fattura di acquisto, sia nel modulo commerciale (ciclo passivo), che nella prima nota del software Business." />
                <CardComponent colorStyle="color-2"frontText="MODULO FATTURA ELETTRONICA PEC" backText="Permette, attraverso il collegamento diretto a una casella di posta elettronica certificata del soggetto emittente, l’invio della fattura elettronica attiva allo SdI, la ricezione dello status delle fatture emesse e la ricezione delle fatture elettroniche passive. La conservazione sostitutiva delle fatture potrà essere effettuata con Business File (soluzione di archiviazione documentale e sostitutiva del gruppo NTS Informatica) oppure delegando la conservazione direttamente all’Agenzia delle Entrate." />
              </div>
            </div>

            <div className="mt-5">
              <h2 className="cards__title">Finanza e controllo di gestione</h2>
              <div className="cards__container">
                <CardComponent colorStyle="color-3" frontText="CONTABILITA' ANALITICA DUPLICE CONTABILE" backText="Attraverso un proprio piano dei conti collegabile alla Contabilità Generale, è possibile ottenere una suddivisione di costi, tra centri di costo o di ricavo principali/produttivi, comuni e ausiliari. Attraverso le logiche di ribaltamento, secondo una molteplicità di driver, è possibile ottenere valori di marginalità sia per centro, che per linea di prodotto, commesse, fino al singolo prodotto. " />
                <CardComponent colorStyle="color-3" frontText="BUDGET E CONTROLLO DI GESTIONE" backText="Attraverso la predisposizione di specifici budget per Area, Centri, Commesse è possibile confrontare gli scostamenti rispetto a quanto prefissato, generare delle revisioni di budget per mantenere sempre alto il controllo sulle performance aziendali." />
                <CardComponent colorStyle="color-3"frontText="TESORERIA E FLUSSI FINANZIARI" backText="Attraverso un controllo puntuale della situazione bancaria e del cash flow è possibile prevedere situazioni di criticità finanziaria, in modo da intraprendere azioni correttive o ottimizzare i flussi bancari. Con il modulo Collegamento a Sistemi di Tesoreria, è possibile automatizzare i processi di registrazione incassi e pagamenti in Business, per ottenere una posizione finanziaria netta dell’azienda sempre allineata." />
              </div>
            </div>

            <div className="mt-5">
              <h2 className="cards__title">Magazzino e logistica</h2>
              <div className="cards__container">
                <CardComponent colorStyle="color-4" frontText="Vendite e fatturazione" backText="Gestione delle anagrafiche articoli normali, a varianti, taglia e colori, a lotti, matricole e con listini associati e possibilità di stampare anche listini su Word. Supporto alla gestione dei barcode e relative stampe etichette. Gestione dei documenti emessi e fatturazione differita con possibilità di contabilizzazione contestuale all’emissione del documento o successiva." />
                <CardComponent colorStyle="color-4" frontText="Agenti e provvigioni" backText="Gestire il parco agenti attraverso la gestione di provvigioni su Fatturato, Incassato o Maturato. Provvigioni definite a percentuale o a valore su singoli articoli, classi articolo / cliente o sconti da listino, fino ad un massimo di 5 agenti per ogni singolo cliente. Con la gestione dei Mandati è possibile creare delle proprie regole di attribuzione fino a raggiungere 10 agenti per documento." />
                <CardComponent colorStyle="color-4" frontText="Magazzino" backText="Interfaccia unica per la gestione di documenti di magazzino sia in ingresso che in uscita. Movimentazione degli articoli gestiti a lotti, a matricole o a commesse. Generazione veloce di DDT o Fatture Immediate da Impegni. Con la gestione dell’inventario è possibile gestire inventari di fine anno o a rotazione con generazione delle rettifiche inventariali." />
                <CardComponent colorStyle="color-4" frontText="Collegamento a magazzini automatizzati" backText="Permette di integrare l’attivazione del magazzino automatico, senza l’obbligo di modifiche sostanziali alle meccaniche dei flussi operativi e documentali previsti nella gestione del magazzino di Business. Tutte le movimentazioni continuano a essere effettuate sul magazzino abituale, in quanto il programma, o l’operatore, genererà le movimentazioni di versamento e prelievo sul magazzino automatico. Lo scambio dati avviene su un database “di frontiera” in SQL e l’interfacciamento è in tempo reale." />
                <CardComponent colorStyle="color-4" frontText="Gestione avanzata varianti" backText="Attraverso il modulo gestione avanzata varianti è possibile configurare, in maniera semplice, articoli fino a 10 varianti, inclusi anche gli articoli a taglie. Sarà possibile generare anche il prezzo di vendita, le descrizioni in lingua e, se l’articolo è di produzione, anche la sua distinta base." />
                <CardComponent colorStyle="color-4" frontText="Contratti e condizioni commerciali" backText="È possibile denire diverse tipologie di promozioni da associare a clienti o gruppi di clienti, espresse su prezzi, sconti, premi, omaggi, con calcolo immediato o differito per la gestione di premi di fine anno. Tali logiche possono essere applicate sia agli acquisti che alle vendite." />
                <CardComponent colorStyle="color-4" frontText="Gestione ordini/impegni" backText="È possibile inserire ordini a fornitori o impegni da clienti che possono essere poi agganciati singolarmente o massivamente a documenti di evasione, con controllo sulla disponibilità/giacenza di magazzino. È possibile confermare singole righe dell’impegno e definire per ogni singola riga una data di consegna." />
                <CardComponent colorStyle="color-4" frontText="Logistica" backText="Con i moduli Logistica su Palmare e Logistica Extended è possibile suddividere ogni magazzino in 5 livelli di ubicazione partendo dalla zona fino alla singola cella. Con la funzionalità di ricevimento merce e movimentazione merce interna è possibile, attraverso l’utilizzo di dispositivi mobili, ottimizzare il processo di disposizione merce sia in ingresso che in uscita (picking) ed effettuare inventari di magazzino su palmare." />
                <CardComponent colorStyle="color-4" frontText="Preventivazione e consuntivazione impianti" backText="Consente una stesura rapida di un progetto complesso, gestendo commesse, materiali, manodopera e impianti, con la possibilità di dividere le opere prestate dall’azienda da quelle prestate da terze parti. È possibile consuntivare in modo semplice e dettagliato i costi reali; il controllo della marginalità di progetto è eseguito in tempo reale grazie alla sezione “Analisi”, in cui è possibile verificare vari parametri di redditività in base alle scelte effettuate dall’utente." />
              </div>
            </div>

            <div className="mt-5">
              <h2 className="cards__title">Gestione punti vendita</h2>
              <div className="cards__container">
                <CardComponent colorStyle="color-5" frontText="Gestione punti vendita" backText="Attraverso un’interfaccia utente semplice è possibile inserire velocemente corrispettivi e documenti di vendita di un tipico negozio al dettaglio con possibilità di collegarsi a registratori di cassa fiscali. Inoltre è prevista la possibilità di inserire movimenti di versamento e prelievo dalla cassa e saldaconto clienti, strettamente legati alla contabilità, rendendo lo strumento GPV un modulo fortemente integrato con Magazzino e Contabilità." />
                <CardComponent colorStyle="color-5" frontText="Gestione punti vendita recovery" backText="Con il modulo Recovery è possibile rendere operativo il singolo punto vendita in modalità offine, necessario nelle situazioni in cui la connessione di rete non è stabile, ma si vuole garantire continuità operativa al singolo punto vendita, schedulando aggiornamenti con il server centrale." />
                <CardComponent colorStyle="color-5" frontText="Gestione punti vendita extended" backText="Permette l’integrazione delle funzionalità di tessere Fidelity, promozioni (accumulo punti per valore o quantità, sconti a percentuale, complessivi, a valore o su listini particolari, 3x2 per articoli similari o misti, omaggi a valore o per articoli specifici), cataloghi premi, configurazione dei pulsanti in maschera associabili a funzionalità di Business Cube." />
              </div>
            </div>

            <div className="mt-5">
              <h2 className="cards__title">CRM</h2>
              <div className="cards__container">
                <CardComponent colorStyle="color-6" frontText="Offerte" backText="Creazione di offerte e relative revisioni con possibilità di una veloce conferma e trasformazione in impegni esecutivi. Definizione di regole per operatore in modo da pilotare gli accessi ai soli lead di competenza dei singoli operatori CRM." />
                <CardComponent colorStyle="color-6" frontText="Lead" backText="L’anagrafica lead permette di gestire un database di contatti senza coinvolgere le anagrafiche di Business Cube. Solo quando il potenziale cliente conferma l’oerta, il lead viene acquisito nelle anagrafiche clienti." />
                <CardComponent colorStyle="color-6" frontText="Opportunità / attività" backText="Possibilità di creare una lista di attività da fare / eseguite e delle opportunità che si presentano sul mercato, per tenere sotto controllo tutte le attività da svolgere, sia proprie che dei propri collaboratori. È inoltre possibile generare massivamente attività CRM a seguito di invio massivo di e-mail o documenti come fatture immediate e differite, per definire attività post vendita da eseguire." />
                <CardComponent colorStyle="color-6" frontText="Obiettivi" backText="Introdotti come ulteriore tipologia di soggetti che precede il lead, gli OBIETTIVI possono essere popolati con l'acquisto di database di nominativi sui quali effettuare attività di marketing per promozione di prodotti, oppure, ad esempio, con elenchi di persone compilati durante eventi o manifestazioni fieristiche (persone che lasciano un biglietto da visita, compilano un modulo di registrazione o una scheda di feedback, ecc.)" />
              </div>
            </div>

            <h2 className="cards__title">..e ancora: Customer Service, Produzione, Workflow, GDPR, Utility varie</h2>
          </div>

        </Row>

      </Container>
    </Layout>
  )
}

export default BusinessCube