import React, { Component } from "react";
import ReactDOM from "react-dom";
import Card from "../components/card";
import '../assets/stylesheets/components/card.scss'


class CardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      colorStyle: "color-1"
    };
    this.hoverOn = this.hoverOn.bind(this);
    this.hoverOff = this.hoverOff.bind(this);
  }

  hoverOn() {
    this.setState({ hover: true });
  }

  hoverOff() {
    this.setState({ hover: false });
  }

  colorChange = (colorStyle) => {
    this.setState({
      colorStyle: colorStyle
    });
  };

  render() {
    return (
      <div>
        <div
          className={this.props.colorStyle || this.state.colorStyle}
          onMouseEnter={this.hoverOn}
          onMouseLeave={this.hoverOff}
        >
          <Card frontText={this.props.frontText} backText={this.props.backText} name={this.state.hover ? "hover-on" : "hover-off"} />
        </div>
      </div>
    );
  }
}

export default CardComponent